import "react-toastify/dist/ReactToastify.css"
import * as S from "./style"
import * as utm_data from "./data"
import { useEffect, useState, useRef } from "react"
import { Layout } from "../../components/Layout"
import { v4 as uuidv4 } from "uuid"
import { api_delete_utm_copy, api_fetch_utms, api_post } from "./helpers/api"
import { preventSpaceKey } from "../Nomenclaturas/helpers/functions"
import { PageTitle } from "../../components/PageTitle"
import { Loading } from "../../components/Loading"
import { useData } from "../../hooks/useData"
import { ToastContainer, toast } from "react-toastify"
import { utmCopyInputValidation } from "../../validations/utm"
import Form from "../../components/Form"
import CopyToClipboard from "../../components/CopyToClipboard"
import UTMCopyCard from "../../components/UTM/UTMCopyCard"
import FieldGroup from "../../components/FieldGroup"
import Button from "../../components/Button"
import UTMList from "../../components/UTM/UTMList"

export const UTMCopy = () => {
   const NOMEN_LOCAL_UTM_DATA = "NOMEN_LOCAL_UTM_COPY_DATA"

   const { data, loading } = useData()

   const utm_source_relations = utm_data.utm_source_relations
   const utm_sources = Object.keys(utm_source_relations)

   const date = new Date().toLocaleDateString("pt-br")

   const [allUtms, setAllUtms] = useState([])
   const [clientFilter, setClientFilter] = useState("")

   const [client, setClient] = useState("")
   const [businessUnit, setBusinessUnit] = useState("")
   const [url, setUrl] = useState("")
   const [campaignSource, setCampaignSource] = useState(utm_sources[0])
   const [campaignMedium, setCampaignMedium] = useState("")
   const [campaignName, setCampaignName] = useState("")
   const [campaignTerm, setCampaignTerm] = useState("")
   const [campaignContent, setCampaignContent] = useState("")
   const [urlParam] = useState("")
   const [etapaDoFunil, setEtapaDoFunil] = useState("")

   const ref = useRef()

   const notifySucess = (copy) => {
      toast.success(copy, {
         position: "top-center",
         autoClose: 3000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: false,
         draggable: false,
         progress: undefined,
         theme: "light",
      })
   }

   async function getAllUTMS() {
      const response = await api_fetch_utms()
      setAllUtms(response)
   }

   useEffect(() => {
      setCampaignName(`bw_${etapaDoFunil ? `${etapaDoFunil}_` : ""}${campaignSource}`)
   }, [etapaDoFunil, campaignSource])

   useEffect(() => {
      setCampaignMedium(utm_source_relations[campaignSource])
   }, [campaignSource, utm_source_relations])

   // UTMS
   useEffect(() => {
      ;(async () => {
         await getAllUTMS()
      })()

      const savedData = JSON.parse(localStorage.getItem(NOMEN_LOCAL_UTM_DATA))
      if (savedData) {
         setClient(savedData.client)
         setUrl(savedData.url)
         setCampaignSource(savedData.campaignSource)
         setCampaignMedium(savedData.campaignMedium)
         setCampaignName(savedData.campaignName)
         setCampaignTerm(savedData.campaignTerm)
         setCampaignContent(savedData.campaignContent)
         setEtapaDoFunil(savedData.etapaDoFunil)
      }
   }, [])

   async function handleUTMStore() {
      const newUrl = {
         identificador: uuidv4(),
         url: url,
         campaignSource,
         campaignMedium,
         campaignName,
         campaignTerm,
         campaignContent,
         urlParam: ref.current.textContent,
         client,
         date: date,
         etapa: etapaDoFunil,
      }

      try {
         await api_post(newUrl)
      } catch (err) {
         console.error(err)
      } finally {
         const localData = {
            client,
            url,
            campaignSource,
            campaignMedium,
            campaignName,
            campaignTerm,
            campaignContent,
            urlParam,
            etapaDoFunil,
         }

         localStorage.setItem(NOMEN_LOCAL_UTM_DATA, JSON.stringify(localData))
         notifySucess("UTM Salva")

         await getAllUTMS()
      }
   }

   async function handleDeleteEntry(id) {
      const resp = window.confirm("Deseja realmente apagar essa UTM?")

      if (!resp) return

      notifySucess("UTM apagada!")

      try {
         await api_delete_utm_copy(id)
      } catch (err) {
         console.error(err)
      } finally {
         const allUtmsEntries = [...allUtms]
         const deletedItem = allUtmsEntries.find((utmCard) => utmCard.identificador === id)
         const index = allUtmsEntries.indexOf(deletedItem)
         if (index > -1) {
            allUtmsEntries.splice(index, 1)
            setAllUtms(allUtmsEntries)
         }
      }
   }

   if (loading) {
      return <Loading />
   }

   const result = `${url ? `${url}?` : "?"}${campaignSource ? `utm_source=${campaignSource}` : ""}${
      campaignMedium ? `&utm_medium=${campaignMedium}` : ""
   }${campaignName ? `&utm_campaign=${campaignName}` : ""}${campaignTerm ? `&utm_term=${campaignTerm}` : ""}${
      campaignContent ? `&utm_content=${businessUnit ? `${campaignContent}_${businessUnit}` : campaignContent}` : ""
   }`

   return (
      <Layout>
         <S.Container className="fade-right">
            <PageTitle>Gerador UTM Copy</PageTitle>

            <Form onSubmit={(e) => e.preventDefault()} style={{ display: "grid" }}>
               <FieldGroup
                  as="select"
                  label="Cliente *"
                  onChange={(e) => setClient(e.target.value)}
                  value={client}
                  options={data.geral[0].clientes}
               />

               <FieldGroup
                  as="input"
                  label="BU"
                  onChange={(e) => setBusinessUnit(e.target.value)}
                  value={businessUnit}
               />

               <FieldGroup
                  as="select"
                  label="Etapa do funil"
                  onChange={(e) => setEtapaDoFunil(e.target.value)}
                  value={etapaDoFunil}
                  options={utm_data.etapa_funil}
                  emptyOption
               />

               <FieldGroup
                  as="input"
                  label="Produto (utm_campaign)"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  onKeyDown={preventSpaceKey}
                  placeholder="Produto"
               />

               <FieldGroup
                  as="input"
                  label="Content (utm_content)"
                  value={campaignContent}
                  onChange={(e) => setCampaignContent(e.target.value)}
                  onKeyDown={preventSpaceKey}
                  onInput={utmCopyInputValidation}
               />

               <FieldGroup
                  as="select"
                  label="Source (utm_source)"
                  value={campaignSource}
                  onChange={(e) => setCampaignSource(e.target.value)}
                  options={utm_sources}
                  emptyOption
               />

               <FieldGroup
                  as="select"
                  label="Term (utm_term)"
                  value={campaignTerm}
                  onChange={(e) => setCampaignTerm(e.target.value)}
                  options={utm_data.utm_term}
                  emptyOption
               />

               <FieldGroup
                  as="input"
                  label="Medium (utm_medium)"
                  value={campaignMedium}
                  onChange={(e) => setCampaignMedium(e.target.value)}
                  onKeyDown={preventSpaceKey}
                  placeholder="Email, organic..."
                  onInput={utmCopyInputValidation}
               />

               <FieldGroup
                  as="input"
                  label="URL Conteúdo *"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="URL"
                  required
                  onKeyDown={preventSpaceKey}
                  onInput={utmCopyInputValidation}
               />
            </Form>

            <S.ResultBox className="span-2">
               <h6 ref={ref}>{result}</h6>

               <CopyToClipboard copyText={result} />
            </S.ResultBox>

            <Button onClick={handleUTMStore}>Guardar UTM</Button>

            <UTMList value={clientFilter} onChange={(e) => setClientFilter(e.target.value)}>
               {allUtms
                  .filter((utm) => utm.client.toLowerCase().includes(clientFilter.toLowerCase()))
                  .map((utm) => (
                     <div key={utm.id}>
                        <UTMCopyCard utm={utm} key={utm.id} handleDeleteEntry={handleDeleteEntry} />
                     </div>
                  ))}
            </UTMList>
         </S.Container>

         <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
      </Layout>
   )
}
