export const FUNIL = ["tofu_mr", "tofu_aw", "mofu_aw", "mofu_mr", "bofu_mr", "bofu_hr"]

export const REGION = ["geral", "BR", "LAS"]

export const CLIENTS = [
   "ACCRUENT",
   "ADOBE ENTERPRISE",
   "ADOBE SMB",
   "BOAVISTA",
   "BOWE",
   "CORPAY",
   "DOCUSIGN",
   "HARPOON",
   "ITS",
   "LENOVO",
   "OMID",
   "LOYMARK",
   "OMID",
   "PARS",
   "RISE RETAIL",
   "ROBBYSON",
   "SEIDOR"
]

export const DATA = {
  
   BOWE: {
      produto: ["abm", "dg"],
   },

   HARPOON: {
      produto: ["geral"],
   },

   "ADOBE SMB": {
      produto: ["acrobatsign", "acrobatpro", "creative-cloud", "cct", "geral"],
   },

   "ADOBE ENTERPRISE": {
      produto: ["acrobatsign", "acrobatpro", "geral"],
   },

   ITS: {
      produto: ["4hana", "grow", "muv", "onepage", "rise", "geral"],
   },

   LENOVO: {
      produto: [
         "AISOLUTIONS",
         "AWARENESS",
         "AMD",
         "CANAIS",
         "CIO_PLAYBOOK",
         "EDGE",
         "HCI",
         "HPC",
         "HX",
         "HYBRIDCLOUD",
         "MX",
         "NVIDIA",
         "ONELENOVO",
         "OUTROS",
         "REDHAT",
         "SERVIDORES",
         "SSG-SAFA",
         "SSG-DWS",
         "SSG-AI-SERVICES",
         "STORAGE",
         "TRUSCALE",
         "VX",
         "HYBRIDAI",
      ],
      regiao: ["BR", "LAS", "MEX", "NOLA", "SOLA"],
   },

   PARS: {
      produto: ["adobepro", "adobesign", "autodesk", "blackduck", "hubspot"],
   },

   ZOOP: {
      produto: ["pix", "payments", "banking", "fintechs", "alltechs", "novo_varejo", "branding"],
   },

   BOAVISTA: {
      produto: ["e-card", "e-marketplace"],
   },
   
}
