import React, { useState } from "react"
import Form from "../../../../../components/Form"
import ResultLabel from "../../../../../components/ResultLabel"
import SectionTitle from "../../../../../components/SectionTitle"
import FieldGroup from "../../../../../components/FieldGroup"
import { useData } from "../../../../../hooks/useData"
import { inputValidity } from "../../../helpers/inputValidity"

const IC = () => {
   const { data } = useData()

   const dataToDisplay = {
      _cliente: data.geral[0].clientes,
      _tipoTarefa: data.drive.ic.tipoTarefa,
   }

   const [cliente, setCliente] = useState(dataToDisplay._cliente[0])
   const [campaign, setCampaign] = useState("")
   const [id, setId] = useState("")
   const [tipoTarefa, setTipoTarefa] = useState(dataToDisplay._tipoTarefa[0])
   const [descricao, setDescricao] = useState("")

   // condicionais
   const [origemDados, setOrigemDados] = useState("")
   const [dataImportacao, setDataImportacao] = useState("")

   return (
      <section id="ic">
         <SectionTitle>Inteligência Comercial</SectionTitle>

         <Form>
            <FieldGroup
               as="select"
               label="Cliente *"
               onChange={({ target }) => setCliente(target.value)}
               value={cliente}
               options={dataToDisplay._cliente}
            />

            <FieldGroup
               as="input"
               label="Campanha"
               value={campaign}
               onChange={({ target }) => setCampaign(target.value)}
            />

            <FieldGroup
               as="input"
               label="ID da tarefa no Runrunit *"
               value={id}
               onChange={({ target }) => setId(target.value)}
               onBlur={inputValidity}
            />

            <FieldGroup
               as="select"
               label="Tipo da tarefa *"
               onChange={({ target }) => setTipoTarefa(target.value)}
               value={tipoTarefa}
               options={dataToDisplay._tipoTarefa}
            />

            {/* condicionais */}
            {tipoTarefa === "Lista bruta recebida" && (
               <FieldGroup
                  as="input"
                  label="Origem dos dados *"
                  value={origemDados}
                  onChange={({ target }) => setOrigemDados(target.value)}
                  onBlur={inputValidity}
               />
            )}

            {tipoTarefa === "Lista importada na ferramenta" && (
               <FieldGroup
                  as="input"
                  label="Data *"
                  value={dataImportacao}
                  onChange={({ target }) => setDataImportacao(target.value)}
                  onBlur={inputValidity}
                  placeholder="dd/mm/aa"
               />
            )}

            <FieldGroup
               as="input"
               label="Descrição ou especificação da lista *"
               value={descricao}
               onChange={({ target }) => setDescricao(target.value)}
               onBlur={inputValidity}
            />
         </Form>

<ResultLabel>{`[${cliente}] - ${id} - ${tipoTarefa}${tipoTarefa === "Lista bruta recebida" ? ` - ${origemDados}` : ""}${tipoTarefa === "Lista importada na ferramenta" ? ` ${dataImportacao}` : ""} - ${campaign !== "" ? ` ${campaign}` : ""} - ${descricao}`}</ResultLabel>

      </section>
   )
}

export default IC
